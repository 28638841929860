import React from "react";

const About = () => {
  return (
    <div className="about">
      <div className="about-info">
        <h1 className="about-title">ABOUT</h1>
        <div className="about-description">
          <p>
            Since childhood I enjoyed sciences requiring logical thinking and
            problem solving (mathematics, chemistry, etc).
          </p>
          <p>
            However, life led to a career in medicine, for which I gave more
            than a decade of my life. It taught me a lot. Taught about hard
            work, responsibility, importance of teamwork, patience, how not only
            to listen to people but to hear them too.
          </p>
          <p>
            Nevertheless, I always felt that I would enjoy technical field of
            work much more.
          </p>
          <p>
            So, in May 2022, I left my previous work in a hospital and dived
            into the world of front-end programming. In June, 2022, I started
            studying in Baltic Institute of Technology, learning the
            fundamentals.
          </p>
          <p>
            I have to say, I love seeing the results of the work I put in
            (believe me, it can be so surprising!) and it motivates me to dig
            deeper, learn more and get more and more comfortable in this
            challenging field.
          </p>
        </div>
        <div className="github">
          <a
            className="gh-link"
            href="https://github.com/sjurate"
            target="_blank"
            rel="noreferrer"
          >
            <span className="fa fa-github"></span>
            <span>Github</span>
          </a>
        </div>
      </div>
      <div className="skills">
        <h3>What I've learnt:</h3>
        <div className="skills-box">
          <div className="bubble1">css</div>
          <div className="bubble2">html</div>
          <div className="bubble3">JS</div>
          <div className="bubble4">mysql</div>
          <div className="bubble5">node.js</div>
          <div className="bubble6">react</div>
        </div>
      </div>
    </div>
  );
};

export default About;
