import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.scss";
import img from "./img/DSC07071c.png";

import Nav from "./Components/Nav";
import Home from "./Components/Home";
import About from "./Components/About";
import Resume from "./Components/Resume";
import Projects from "./Components/Projects";
import Contact from "./Components/Contact";
import Extra from "./Components/Extra";

function App() {
  return (
    <BrowserRouter>
      <div className="wrap">
        <img className="bg" src={img} alt="" />
        <div className="container page">
          <Nav className="navigation " />
          <div className="row">
            <div className="main-page ml-sm-1 ml-md-2 ml-lg-3 col-xl-9 col-lg-9 col-md-9 col-sm-11">
              <Routes>
                <Route path="/" element={<Home />}></Route>
                <Route path="/about" element={<About />}></Route>
                <Route path="/resume" element={<Resume />}></Route>
                <Route path="/projects" element={<Projects />}></Route>
                <Route path="/contact" element={<Contact />} />
                <Route path="/extra" element={<Extra />} />
              </Routes>
            </div>
          </div>
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
