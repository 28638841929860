import React from "react";

const Contact = () => {
  return (
    <div className="contacts">
      <div className="all-contacts">
        <div className="contact-single">
          <div className="fa fa-user"></div>
          <div className="contact-item">JŪRATĖ SVEIKAUSKĖ</div>
        </div>
        <div className="contact-single">
          <div className="fa fa-linkedin-square"> </div>
          <a
            href="https://www.linkedin.com/in/jurate-sveikauske"
            target="_blank"
            rel="noreferrer"
            className="contact-item"
          >
            Linked In
          </a>
        </div>
        <div className="contact-single">
          <div className="fa fa-envelope-o"></div>
          <div className="contact-item">rasaujuratei@gmail.com</div>
        </div>
        <div className="contact-single">
          <div className="fa fa-phone"></div>
          <div className="contact-item">+370 654 62528</div>
        </div>
        <div className="contact-single">
          <div className="fa fa-map-marker"></div>
          <div className="contact-item">Vilnius, Lithuania</div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
