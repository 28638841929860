import React, { useState } from "react";
import Carousel from "./Carousel";

const Extra = () => {
  const [correct, setCorrect] = useState(false);
  const [answer, setAnswer] = useState("");
  const pass = "js";

  const submit = () => {
    if (answer === pass) {
      setCorrect((prev) => true);
    }
  };

  return (
    <div className="extra-container">
      <h1 className="extra-title">smth extra</h1>

      <div className="extra-box">
        {correct ? (
          <Carousel />
        ) : (
          <div className="extra-form">
            <div>
              * If you know a password or if you know how to "hack" it 😉
            </div>
            <label className="extra-label">Password:</label>
            <input
              className="extra-input"
              onChange={(e) => setAnswer(e.target.value)}
            ></input>
            <button className="extra-btn" onClick={submit}>
              submit
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Extra;
