import React from "react";

const NavBtn = ({ setShowLinks, showLinks }) => {
  return (
    <div
      className="nav-btn"
      onClick={() => setShowLinks(!showLinks)}
      id={showLinks ? "btn-right" : ""}
    >
      <div className="nav-icon">
        <input
          className="nav-icon__cheeckbox"
          type="checkbox"
          checked={showLinks}
          readOnly
        />
        <div>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>
  );
};

export default NavBtn;
