import React from "react";
import img from "../img/profilec.png";

const Home = () => {
  return (
    <div className="home">
      <div className="home-img">
        <img className="img" src={img} alt="JS" />
      </div>
      <div className="home-info">
        <h1 className="home-name">Jūratė Sveikauskė</h1>
        <div className="home-description">
          On a journey of changing my career. From a medical doctor to Front-End
          developer!
        </div>
        <div className="bubble">
          <a href="/about">more</a>
        </div>
      </div>
    </div>
  );
};

export default Home;
