import React from "react";
import medrek from "../img/medrek.png";
import forkify from "../img/forkify.png";
import minesweeper from "../img/minesweeper.png";

const Projects = () => {
  return (
    <div className="projects-container">
      <h1 className="projects-title">PROJECTS</h1>
      <div className="projects-box">
        <div className="project-single">
          <div className="img-wrap">
            <div className="project-img-icons">
              <a
                href="https://medrek.netlify.app/"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa fa-eye" aria-hidden="true"></i>
              </a>
              <a
                href="https://github.com/sjurate/med-rek"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa fa-github" aria-hidden="true"></i>
              </a>
            </div>
            <img className="project-img" src={medrek} alt="MedRek"></img>
          </div>
          <h4>MedRek</h4>
          <p>App for writing treatment recommendations</p>
        </div>
        <div className="project-single">
          <div className="img-wrap">
            <div className="project-img-icons">
              <a
                href="https://forkify-jurate.netlify.app/"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa fa-eye" aria-hidden="true"></i>
              </a>
              <a
                href="https://github.com/sjurate/forkify"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa fa-github" aria-hidden="true"></i>
              </a>
            </div>
            <img className="project-img" src={forkify} alt="Forkify"></img>
          </div>
          <h4>Forkify</h4>
          <p>App for searching and bookmarking recipes</p>
        </div>
        <div className="project-single">
          <div className="img-wrap">
            <div className="project-img-icons">
              <a
                href="https://minesweeper-game-js.netlify.app/"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa fa-eye" aria-hidden="true"></i>
              </a>
              <a
                href="https://github.com/sjurate/minesweeper"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa fa-github" aria-hidden="true"></i>
              </a>
            </div>
            <img
              className="project-img"
              src={minesweeper}
              alt="Minesweeper"
            ></img>
          </div>
          <h4>Minesweeper</h4>
          <p>Classic game clone</p>
        </div>
      </div>
    </div>
  );
};

export default Projects;
