import React, { useState } from "react";
// import experience from "../data/data";

const Resume = () => {
  const [info1, setInfo1] = useState(false);
  const [info2, setInfo2] = useState(false);
  const [info3, setInfo3] = useState(false);
  const [info4, setInfo4] = useState(false);
  const [info5, setInfo5] = useState(false);

  return (
    <div className="resume">
      <div className="work">
        <h1 className="resume-title">RESUME</h1>
        <ul className="exp-list">
          <li className="single-exp">
            <div className="exp-official">
              <div className="exp-date">Jun 2019 - May 2022</div>
            </div>
            <div className="exp-info">
              <div className="title-btn">
                <h4 className="exp-title" onClick={() => setInfo1(!info1)}>
                  Medical Doctor
                </h4>
                <button onClick={() => setInfo1(!info1)}>?</button>
              </div>
              <span className="exp-workplace">
                "Gemma" slaugos ir reabilitacijos centras
              </span>
              {info1 ? (
                <div className="exp-details">
                  Worked in a nursing hospital where had to manage daily health
                  challenges of patients suffering after serious illnesses.
                </div>
              ) : null}
            </div>
          </li>
          <li className="single-exp">
            <div className="exp-official">
              <div className="exp-date">Jul 2019 - Dec 2019</div>
            </div>
            <div className="exp-info">
              <div className="title-btn">
                <h4 className="exp-title" onClick={() => setInfo2(!info2)}>
                  Medical Doctor
                </h4>
                <button onClick={() => setInfo2(!info2)}>?</button>
              </div>
              <span className="exp-workplace">Nacionalinis kraujo centras</span>
              {info2 ? (
                <div className="exp-details">
                  Worked with blood donors, evaluating their state of health and
                  ability to donate blood safely.
                </div>
              ) : null}
            </div>
          </li>
          <li className="single-exp">
            <div className="exp-official">
              <div className="exp-date">Sep 2018 - May 2019</div>
            </div>
            <div className="exp-info">
              <div className="title-btn">
                <h4 className="exp-title" onClick={() => setInfo3(!info3)}>
                  Senior Specialist
                </h4>
                <button onClick={() => setInfo3(!info3)}>?</button>
              </div>
              <span className="exp-workplace">
                Neįgalumo ir darbingumo nustatymo tarnyba (NDNT)
              </span>
              {info3 ? (
                <p className="exp-details">
                  Worked in a government institution evaluating level of
                  decreased working capacity and disabilities due to various
                  illnesses.
                </p>
              ) : null}
            </div>
          </li>
          <li className="single-exp">
            <div className="exp-official">
              <div className="exp-date">Apr 2017 - Jun 2018</div>
            </div>
            <div className="exp-info">
              <div className="title-btn">
                <h4 className="exp-title" onClick={() => setInfo4(!info4)}>
                  Medical Doctor
                </h4>
                <button onClick={() => setInfo4(!info4)}>?</button>
              </div>
              <span className="exp-workplace">
                Respublikinė Šiaulių ligoninė
              </span>
              {info4 ? (
                <div className="exp-details">
                  Worked in an Emergency Room department.
                </div>
              ) : null}
            </div>
          </li>
          <li className="single-exp">
            <div className="exp-official">
              <div className="exp-date">Sep 2015 - Apr 2017</div>
            </div>
            <div className="exp-info">
              <div className="title-btn">
                <h4 className="exp-title" onClick={() => setInfo5(!info5)}>
                  Resident Doctor
                </h4>
                <button onClick={() => setInfo5(!info5)}>?</button>
              </div>
              <div className="exp-workplace">
                Vilniaus Universiteto Santariškių klinikos
              </div>
              {info5 ? (
                <div className="exp-details">
                  Worked in various therapeutic departments under a supervision
                  of consulting doctors.
                </div>
              ) : null}
            </div>
          </li>
        </ul>
      </div>
      <div className="education">
        <h1 className="resume-title">EDUCATION</h1>
        <ul className="exp-list">
          <li className="single-exp">
            <div className="exp-official">
              <div className="exp-date">Jun 2022 - November 2022</div>
            </div>
            <div className="exp-info">
              <div className="title-btn">
                <h4 className="exp-title">Front-end development course</h4>
              </div>
              <span className="exp-workplace">
                Baltic Institute of Technology (BIT)
              </span>
            </div>
          </li>
          <li className="single-exp">
            <div className="exp-official">
              <div className="exp-date">Sep 2009 - Jun 2015</div>
            </div>
            <div className="exp-info">
              <div className="title-btn">
                <h4 className="exp-title">Master's degree, medicine</h4>
              </div>
              <span className="exp-workplace">Vilniaus Universitetas</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Resume;
