import React, { useState } from "react";
import { images } from "../Helpers/CarouselData";

const Carousel = () => {
  const [currImg, setCurrImg] = useState(0);

  const goBackwards = () => {
    currImg > 0
      ? setCurrImg((prev) => prev + -1)
      : setCurrImg((prev) => images.length - 1);
  };

  const goForwards = () => {
    currImg < images.length - 1
      ? setCurrImg((prev) => prev + 1)
      : setCurrImg((prev) => 0);
  };

  return (
    <div className="carousel">
      <h4 className="extra-about">
        On a less serious note... As a colleague I...
      </h4>
      <div className="carousel-title">{images[currImg].title}</div>
      <div className="carousel-inner">
        <div className="left">
          <i
            className="fa fa-angle-left carousel-btn"
            aria-hidden="true"
            onClick={goBackwards}
          ></i>
        </div>
        <div className="carousel-img">
          <img src={images[currImg]?.img} alt={images[currImg]?.img} />
        </div>
        <div className="right">
          <i
            className="fa fa-angle-right carousel-btn"
            aria-hidden="true"
            onClick={goForwards}
          ></i>
        </div>
      </div>
    </div>
  );
};

export default Carousel;
