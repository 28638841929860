import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import logo from "../img/JS-logo.png";
import NavBtn from "./NavBtn";
import Footer from "./Footer";

function Nav() {
  const [showLinks, setShowLinks] = useState(false);

  const navigate = useNavigate();

  const goHome = () => {
    navigate("/", { replace: true });
    setShowLinks(!showLinks);
  };

  return (
    <>
      <div className="navbar" id={showLinks ? "isVisible" : ""}>
        <nav className="navbar-links">
          <div className="navbar-logo" onClick={goHome}>
            <img src={logo} alt="JS" />
          </div>
          <div>
            <div
              className="navbar-nav"
              onClick={() => setShowLinks(!showLinks)}
            >
              <NavLink className="link" to="/" end>
                Home
              </NavLink>
              <NavLink className="link" to="/about">
                About
              </NavLink>
              <NavLink className="link" to="/resume">
                Resume
              </NavLink>
              <NavLink className="link" to="/projects">
                Projects
              </NavLink>
              <NavLink className="link" to="/contact">
                Contact Me
              </NavLink>
            </div>
          </div>
        </nav>
        <div className="extra-link">
          <NavLink className="link" to="/extra">
            smth extra
          </NavLink>
        </div>
        <Footer className="col-9" />
      </div>
      <NavBtn setShowLinks={setShowLinks} showLinks={showLinks} />
    </>
  );
}

export default Nav;
