import Dog from "../img/dog.jpg";
import Pie from "../img/pie.jpg";
import Wisdom from "../img/wisdom.jpg";
import Hug from "../img/hug.jpg";

export const images = [
  {
    title: "...sometimes bake pies",
    img: Pie,
  },
  {
    title: "...am open to pet sit your puppy!",
    img: Dog,
  },
  {
    title: '...am willing to share learnt  "wisdom"',
    img: Wisdom,
  },
  {
    title: "...can provide a necessary hug on a bad day",
    img: Hug,
  },
];
